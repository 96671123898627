<template>
  <div class="aboutOurCompany">
    <div class="title"><h3>جولات رئيس مجلس الإداره</h3></div>

    <v-divider></v-divider>
    <v-container>
      <v-row>
        <h4 class="blue--text">
          شركه مياه الشرب والصرف الصحي بأسيوط والوادي الجديد
        </h4>
        <h4>/ المعرض</h4>
        <h4>/ جولات رئيس مجلس الإداره</h4></v-row
      >
      <v-row justify="center" class="mt-10">
        <v-col
          v-for="(expandImg, i) in ImagesLab"
          :key="i"
          class="pa-1 ma-1"
          xs="8"
          sm="5"
          md="5"
          lg="3"
          cols="8"
        >
          <expandable-image
            class="Labimg scale-in-center"
            lazy-src="@/assets/Images/loading.gif"
            :src="expandImg"
          
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
          
<script>
export default {
  data() {
    return {
           loadingLink:require("@/assets/Images/load.svg"),
      ImagesLab: [
        require("@/assets/Images/trip_boss/111.jpg"),
        require("@/assets/Images/trip_boss/112.jpg"),
        require("@/assets/Images/trip_boss/113.jpg"),
        require("@/assets/Images/trip_boss/114.jpg"),
        require("@/assets/Images/trip_boss/115.jpg"),
        require("@/assets/Images/trip_boss/116.jpg"),
        require("@/assets/Images/trip_boss/117.jpg"),
        require("@/assets/Images/trip_boss/118.jpg"),
        require("@/assets/Images/school_dep/107.jpg"),
        require("@/assets/Images/school_dep/109.jpg"),
        require("@/assets/Images/school_dep/110.jpg"),
      ],
    };
  },
};
</script>
<style>
.Labimg {
  box-shadow: gray 3px 3px 3px 3px;
}
</style>